import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useEffect, useState } from "react";

export default function AnimationWrapper(): null {
    gsap.registerPlugin(ScrollTrigger);

    // Animation for the header h1 text.
    const target = ".header-animation > .mask > .header-animation__text";

    gsap.to(target, {
        y: 0,
        stagger: 0.1,
        ease: "var(--animation-ease)",
        scrollTrigger: {
            trigger: ".header-animation"
        },
        duration: 0.6
    });

    const [allImagesLoaded, setAllImagesLoaded] = useState(false);

    useEffect(() => {
        const imageTarget = ".image-container > img";
        const images = Array.from(
            document.querySelectorAll(imageTarget)
        ) as HTMLImageElement[];

        let loadedImagesCount = 0;

        images.forEach(image => {
            const handleImageLoad = () => {
                loadedImagesCount++;
                if (loadedImagesCount === images.length) {
                    setAllImagesLoaded(true);
                }
            };

            if (image.complete) {
                handleImageLoad();
            } else {
                image.addEventListener("load", handleImageLoad);
            }
        });
    }, []);

    useEffect(() => {
        if (allImagesLoaded) {
            const imageTarget = ".image-container > img";
            const images = Array.from(
                document.querySelectorAll(imageTarget)
            ) as HTMLImageElement[];

            let i = 0;
            const showDuration = 1500;
            const intervalDuration = showDuration * 2; // Double the showDuration

            const intervalId = setInterval(() => {
                // Start by hiding all.
                images.forEach(image => {
                    image.style.opacity = "0";
                });

                images[i].style.opacity = "1";
                i = (i + 1) % images.length;
            }, intervalDuration);

            // Clear the interval when the component is unmounted
            return () => clearInterval(intervalId);
        }
    }, [allImagesLoaded]);

    return null;
}
